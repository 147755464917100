

export const messages = {
    "Cancel Button Text":"Cancel",
    "Convert to PEM button":"Convert to PEM",
    "Download LS data button":"Download Data",
    "accept button text":"ACCEPT",
    "admin error header":"Please join again from your membership page to access the admin features",
    "admin header":"AdminDialog Controls",
    "cancel button text":"Cancel",
    "change room capacity label":"Change Room Capacity",
    "change username label":"Change Username",
    "change username me button text":"Me",
    "confirm restriction message":"Restriction will cause new encryption keys to be generated, and will only be shared to participants that you as Owner explicitly approve. New participants can still whisper to you, but not send or receive messages from anybody else.",
    "confirm room restriction button text":"Confirm Room Restriction",
    "copy paste ls message":"You can also copy-paste the following:",
    "first visit modal message":"Welcome! If this is the first time you've been to this room, enter your username for this room and press 'Ok' and we we will generate fresh cryptographic keys that are unique to you and to this room. If you have already been here, then you might want to load your keys from your backup - press 'Cancel' and we'll take you to the 'Home' tab.",
    "guide bullet points":"<0>The first time you join a room, a cryptographic public/private key pair will be generated for you and stored in the localstorage of your browser. This will serve as your identity for the room. Do not share the private key with anyone. If you are the room owner, the SSO will manage your keys for you unless you explicitly choose not to. For all other visitors, if you want to preserve your identity across browsers, you can just add the private key to the localstorage for that browser. If you clear your localstorage, you will lose the key and there is no way to retrieve it. A new pair will be generated and hence you get a new identity.</0><1>Anyone who has joined this room can send a message to everyone else.</1><2>If you are the room owner, you can whisper a message to anyone by long pressing their user icon. If you are a visitor, you can whisper a message to the room owner by tapping/clicking on the user icon in the top right corner. By doing this, only the recipient will be able to read the message you send.</2><3>If a message shows as \"whispered\" with yellow background, someone else whispered a message that is not meant for you.</3><4>Messages sent by the room creator will have a green border. Messages with a red border are probably tampered with, so be cautious about those. Messages with a purple border were sent by the first guest joining the room.</4><5>Each room can be 'restricted' by the Owner. A restricted room will have a green 'lock' symbol next to its name. This means you will only be able to talk with anybody in the room if you have been approved, though you can always whisper the Owner</5><6>The 'Home' tab maintains a list of all the rooms for which the keys are found in your localstorage. You can also export/import keys from/to the LocalStorage from there.</6><7>We will soon add support for sending files as well.</7>",
    "guide introduction":"You are probably here because you scanned a social card that someone gave you or received a url for this room from someone. Here are some general guidelines about the chat application to help you get familiar.",
    "join requests header":"Join Requests",
    "key export filename label":"Enter filename you want to save as:",
    "key export header":"Export Keys",
    "key export ls empty message":"Your localstorage does not have any data to export!",
    "key import header":"Import Keys",
    "key import paste message":"Or paste the keys you want to import",
    "loading room text":["Loading room...",["0"]],
    "member message":"If you are a <0>Member</0>, you can log in on your membership page, which will automatically initialize your “social card” rooms in the browser.",
    "motd label":"Enter the Message of the Day below:",
    "motd text":["Message of the day: ",["0"]],
    "new room button":"Create New Room",
    "new room header":"Create New Room",
    "new room message":"Create a new room by entering your room server secret and pressing 'Create'. This will generate the necessary keys for the new room and initialize the room for you on your server.",
    "no join requests text":"No new join requests for this room!",
    "ok button text":"Ok",
    "restrict button text":"Restrict",
    "room authorize button":"Authorize Room",
    "room authorize header":"Authorize Room",
    "room authorize message":"You can authorize a room to be made available on your server. To do that, enter the roomId, the public key of the room owner and your server secret and press Authorize Room.",
    "room list header":"Rooms",
    "room rename header modal":"Rename Room",
    "room upload button":"Upload Room Data",
    "room upload header":"Upload/Create Room",
    "room upload message":"You can upload room data for a previously generated room or create a new room by entering your server secret and pressing the button below. Creating a new room will generate the necessary keys for the new room and initialize the room for you on your server.",
    "save button text":"Save",
    "send button text":"Send",
    "storage upload button":"Upload Storage Data",
    "storage upload header":"Upload Storage Data",
    "storage upload message":"You can upload storage data for a previously generated room by entering your storage server secret and pressing the button below.",
    "update button text":"Update",
    "welcome header":"Welcome to Snackabra",
    "whisper header":["Whisper to ",["0"]]
}

